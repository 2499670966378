import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import styled from 'styled-components'
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
      "@type": "Question",
      "name": "What Does A Java Developer Do?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "A java developer designs, develops and manages java-based applications. Java is used widely, especially by large organizations, so the daily roles vary widely but can include owning a certain application or working on many at one time."
      }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
      "@type": "Question",
      "name": "Why Do I Need Java Experts?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "There are many reasons why you need java experts. Java is one of the most used languages in the world. So if you want to develop applications for your business in java, you will need Codestaff java experts that will get your application to the market quickly and help you to avoid problems with your application."
      }
    }
}

const productMeta = {
    "@context": "https://schema.org/", 
    "@type": "Product", 
    "name": "Freelance Java Developer",
    "image": "https://codestaff.io/java-developer.png",
    "description": "Freelance Java Developers at Code Staff have all of the skill necessary to help your next project succeed. Hire a Remote Web Developer and have them join your team.",
    "brand": "Code Staff",
    "offers": {
      "@type": "Offer",
      "url": "https://codestaff.io/java-developer",
      "priceCurrency": "USD",
      "price": "99",
      "priceValidUntil": "2020-05-27",
      "availability": "https://schema.org/OnlineOnly",
      "itemCondition": "https://schema.org/NewCondition"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "worstRating": "1",
      "ratingCount": "1",
      "reviewCount": "1"
    },
    "review": {
      "@type": "Review",
      "name": "Chad",
      "reviewBody": "Always a pleasure to work with. Would not hesitate to work with him again.",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5",
        "bestRating": "5",
        "worstRating": "1"
      },
      "datePublished": "2020-05-06",
      "author": {"@type": "Person", "name": ""},
      "publisher": {"@type": "Organization", "name": "Colin and Finn"}
    }
}

const MainWrapper = styled.div`
margin: 0;
`

const JavaDeveloper = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
            <script type="application/ld+json">{JSON.stringify(productMeta)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Java Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Java Developers. Top
                                    companies and start-ups choose Codestaff professional Java Developers
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Java Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE JAVA DEVELOPERS' banner='/java-developer.png' bannerAlt='java developer banner'/>
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default JavaDeveloper